<template>
    <v-app>
        <v-main>
            <template v-if="$route.meta.requiresAuth">
                <Menu />
                <Toolbar />
                <v-container fluid>
                    <transition name="slide-x-transition" mode="out-in">
                      <router-view :key="$route.name"></router-view>
                    </transition>
                </v-container>
            </template>
            <template v-else>
                <transition name="slide-x-transition" mode="out-in">
                  <router-view :key="$route.name"></router-view>
                </transition>
            </template>
        </v-main>

        <p class="copyright">Desenvolvido por Vox Cognitiva Tecnologia - {{anoAtual}} - SPTech &copy; - Versão {{version}}</p>
        <Loader />
    </v-app>
</template>

<script>

import Vue from 'vue';
import Loader from '@/components/Layout/Loader';
import Menu from "@/views/Main/Menu";
import Toolbar from "@/views/Main/Toolbar";
import moment from 'moment';

export default {
  name: 'App',

  components: {
    Loader,
    Menu,
    Toolbar,
  },

  data() {
    return {
      anoAtual: moment(new Date()).format('YYYY'),
      version: ''
    }
  },

  created() {
    Vue.prototype.getApp = this;
    Vue.prototype.getToast = this.$toast;
    Vue.prototype.getDialog = this.$dialog;
    Vue.prototype.getRouter = this.$router;
    Vue.prototype.getRoot = this.$root;
    this.version = process.env.VUE_APP_VERSION;
  },
};
</script>

<style>

  .copyright {
    direction: rtl;
    font-size: small;
    background-color: var(--color__main);
    color:white;
    height: 30px;
    line-height: 30px;
    margin-bottom: 0px !important;
    text-align: center;
  }

</style>