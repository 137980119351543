export const LIST_RELEASES = [
    { 
        versao: "2.2.1", 
        data: "23/09/2024", 
        listDescricoes: [
            "Histórico de produtos",
            "Mostrar ultimas atualizações feitas",
        ]
    },
    { 
        versao: "2.2.2", 
        data: "27/09/2024", 
        listDescricoes: [
            "Tratativa na flag de desconto de campanha",
            "Correção na validação de compra mínima",
        ]
    },
    { 
        versao: "2.2.3", 
        data: "09/10/2024", 
        listDescricoes: [
            "Separação do custo base",
            "Atualização do prazo entrega na integração com o totvs",
            "Correção da multiplicação por 100 na edição da cotação",
            "Validação de margem minima no carrinho para não travar"
        ]
    },
    { 
        versao: "2.2.4", 
        data: "10/10/2024", 
        listDescricoes: [
            "Correções no calculo do custo base",
        ]
    },
]