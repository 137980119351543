import { formaterDecimalBRServer, formaterDecimalBR } from '@/utilities/Utils';

export function produtoFormatServer(produtoRequest) {

    if (produtoRequest.status === null) {
        produtoRequest.status = 0;
    }

    if (produtoRequest.codigoFechado === null) {
        produtoRequest.codigoFechado = 0;
    }

    if (produtoRequest.importado === null) {
        produtoRequest.importado = 0;
    }

    if (produtoRequest.visivelUsuarioInterno === null) {
        produtoRequest.visivelUsuarioInterno = 0;
    }

    if (produtoRequest.certificacaoEX === null) {
        produtoRequest.certificacaoEX = 0;
    }

    if (produtoRequest.prazoEntregaDefinido === null) {
        produtoRequest.prazoEntregaDefinido = 0;
    }

    if (produtoRequest.prazoEntrega == "") {
        produtoRequest.prazoEntrega = 0;
    }

    if (produtoRequest.compraMinima === null) {
        produtoRequest.compraMinima = 0;
        produtoRequest.compraMinimaQtde = 1;
    }

    if (produtoRequest.obsoleto === null) {
        produtoRequest.obsoleto = 0;
        produtoRequest.textoObsoleto = "";
    }

    produtoRequest.precoCusto = formaterDecimalBRServer(produtoRequest.precoCusto);
    produtoRequest.idTipoProduto = produtoRequest.tipoProdutoSelected.id;
    produtoRequest.idUnidadeMedida = produtoRequest.unidadeMedidaSelected.id;
    produtoRequest.idCambio = produtoRequest.cambioSelected.id;
    produtoRequest.idNcm = produtoRequest.ncmSelected.id;
    produtoRequest.idEstadoOrigem = 0;
    produtoRequest.idCidade = 0;
    
    if (produtoRequest.cidadeSelected != null && produtoRequest.cidadeSelected.id != undefined) {
        produtoRequest.idCidade = produtoRequest.cidadeSelected.id;
    }

    if (produtoRequest.estadoOrigemSelected != null && produtoRequest.estadoOrigemSelected.id != undefined) {
        produtoRequest.idEstadoOrigem = produtoRequest.estadoOrigemSelected.id;
    }
    produtoRequest.aliqPIS = formaterDecimalBRServer(produtoRequest.aliqPIS)
    produtoRequest.aliqCOFINS = formaterDecimalBRServer(produtoRequest.aliqCOFINS)
    produtoRequest.margem = formaterDecimalBRServer(produtoRequest.margem)
    produtoRequest.idCambioICP = produtoRequest.cambioICPSelected.id;
    produtoRequest.valorICP = formaterDecimalBRServer(produtoRequest.valorICP)

    produtoRequest.listParametroRequest.forEach (itemParametro => {

        if (produtoRequest.id == 0) {
            itemParametro.id = 0;
        }

        if (itemParametro.listParametroItemRequest != null && itemParametro.listParametroItemRequest != undefined) {
            
            itemParametro.listParametroItemRequest.forEach (itemParametroItem => {

                if (produtoRequest.id == 0) {
                    itemParametroItem.id = 0;
                }
                
                itemParametroItem.precoCusto = formaterDecimalBRServer(itemParametroItem.precoCusto);
                itemParametroItem.margem = formaterDecimalBRServer(itemParametroItem.margem);
                
                itemParametroItem.idCambioICP = produtoRequest.idCambioICP;
                itemParametroItem.valorICP = formaterDecimalBRServer(itemParametroItem.valorICP);
                
                if (itemParametroItem.status === null || itemParametroItem.status == false) {
                    itemParametroItem.status = 0;
                }
                else if (itemParametroItem.status == true) {
                    itemParametroItem.status = 1;
                }
                
                if (itemParametroItem.certificacaoEX === null || itemParametroItem.certificacaoEX == false) {
                    itemParametroItem.certificacaoEX = 0;
                }
                else if (itemParametroItem.certificacaoEX == true) {
                    itemParametroItem.certificacaoEX = 1;
                }
                
                if (itemParametroItem.obsoleto === null || itemParametroItem.obsoleto == false) {
                    itemParametroItem.obsoleto = 0;
                    itemParametroItem.textoObsoleto = "";
                }
                else if (itemParametroItem.obsoleto == true) {
                    itemParametroItem.obsoleto = 1;
                }
            })
        }
    })

    let newListSubEstrutura = [];

    produtoRequest.listSubEstruturaRequest.forEach (itemSubEstrutura => {

        if (produtoRequest.id == 0) {
            itemSubEstrutura.id = 0;
        }

        itemSubEstrutura.custo = formaterDecimalBRServer(itemSubEstrutura.custo)

        if (itemSubEstrutura.nome != null && itemSubEstrutura.nome != undefined && itemSubEstrutura.nome != "") {
            newListSubEstrutura.push(itemSubEstrutura);
        }

    })
    produtoRequest.listSubEstruturaRequest = newListSubEstrutura;

    produtoRequest.listPrazoEntrega.forEach (itemPrazoEntrega => {
        if (itemPrazoEntrega.prazoEntregaDefinido === null) {
            itemPrazoEntrega.prazoEntregaDefinido = 0;
        }

        if (itemPrazoEntrega.prazoEntrega == "" || 
            itemPrazoEntrega.prazoEntrega == null || 
            itemPrazoEntrega.prazoEntregaDefinido == 0) {
            itemPrazoEntrega.prazoEntrega = 0;
        }
    })
    
    return produtoRequest;
}

export function produtoFormatFront(produtoRequest) {

    produtoRequest.precoCusto = formaterDecimalBR(produtoRequest.precoCusto);
    produtoRequest.aliqPIS = formaterDecimalBR(produtoRequest.aliqPIS)
    produtoRequest.aliqCOFINS = formaterDecimalBR(produtoRequest.aliqCOFINS)
    produtoRequest.margem = formaterDecimalBR(produtoRequest.margem)
    produtoRequest.valorICP = formaterDecimalBR(produtoRequest.valorICP)

    produtoRequest.listParametroRequest.forEach (itemParametro => {

        if (itemParametro.listParametroItemRequest != null && itemParametro.listParametroItemRequest != undefined) {
            
            itemParametro.listParametroItemRequest.forEach (itemParametroItem => {
                itemParametroItem.precoCusto = formaterDecimalBR(itemParametroItem.precoCusto);
                itemParametroItem.margem = formaterDecimalBR(itemParametroItem.margem);
                itemParametroItem.valorICP = formaterDecimalBR(itemParametroItem.valorICP);
            })
        }
    })

    produtoRequest.listSubEstruturaRequest.forEach (itemSubEstrutura => {

        itemSubEstrutura.custo = formaterDecimalBR(itemSubEstrutura.custo)

    })
    
    return produtoRequest;
}